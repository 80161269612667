import React from "react";
import Blog from "../../../components/blog";
import usePosts from "../../../hooks/use-posts";

type Props = {
  data: {
    allPost: any;
    [key: string]: string;
  };
  [key: string]: any;
};

export default function MinimalBlogCoreBlog({ ...props }: Props) {
  const nodes = usePosts();
  return <Blog posts={nodes} {...props} />;
}
